module.exports = [{
      plugin: require('../plugins/gatsby-plugin-backend-redirect/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-adobe-launch/gatsby-browser.js'),
      options: {"plugins":[],"scriptUrl":"//assets.adobedtm.com/fb197b08aae9/00b83e8649c9/launch-b26e40a1a155-staging.min.js","includeInDevelopment":true,"routeChangeEventName":"gatsbyRouteChange","dataLayerName":"rotaryDDO","defaultDataLayer":{"pageData":{"language":"English","pageID":"home","pageName":"My Rotary Web App (qat) - My Rotary","pageRef":"","pageTitle":"My Rotary"},"siteData":{"siteDomain":"https://myqat.rotary.org","siteName":"My Rotary Web App (qat)"},"userData":{"loginStatus":"not logged in","memberClass":"","userAge":"","userProfileID":""}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Rotary","short_name":"My Rotary","start_url":"/","background_color":"#0c3c7c","theme_color":"#0c3c7c","display":"standalone","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dcf63410fce77e4a51ba11a9b721bd3e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
