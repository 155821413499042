import { gql } from '@apollo/client';

export const fetchBasicInfo = gql`
  query basicInfo($emailId: String = "") {
    basicInfo(emailId: $emailId) {
      oktaProfileData {
        firstName
        lastName
        emailAddress
        username
        mobileNumber
        registrationType
        registrationForm
      }
      crmIndividualInfo {
        individualKey
        preferredLanguage
        memberId
        userSortName
      }
      groupApplicationRolesRelationship {
        name
        id
        roles {
          roleKey
          roleName
        }
        applications {
          name
          linkUrl
          appInstanceId
        }
        accessFlag
        message
        errorId
        errorCode
        containErrors
      }
      rolesAuthenticationTicketData {
        clubId
        districtId
        districtKey
        individualKey
        memberId
        rotaryClubKey
        userSortName
        firstName
        lastName
        role
        preferredLanguage
        positionType
        termBeforeDuration
        termAfterDuration
        termStartDate
        termEndDate
        roleKey
        delegation
        delegationStartDate
        delegationEndDate
        delegatorSortName
        delegatorIndividualKey
        delegatorMemberId
        rotaractClubId
        rotaractClubKey
        clubGroupId
        clubGroupKey
        regionalGroupId
        regionalGroupKey
        regionalGroupName
        clubGroupName
      }
    }
  }
`;
