import { useEffect, useState } from 'react';

import { LayoutServiceData } from '@sitecore-jss/sitecore-jss-react';
import axios, { AxiosRequestConfig } from 'axios';
import promiseRetry from 'promise-retry';

import { FetchSiteCoreLayoutError, SitecoreRoute } from '@domain/appConfig';
import { SITECORE_USER_TYPES } from '@domain/clubs';

import { useBasicInfo } from '@repositories/basicInfo';

import {
  FEATURE_SITECORE_FOOTER,
  FEATURE_SITECORE_HEADER,
  FEATURE_SITECORE_HOMEPAGE,
  FEATURE_SITECORE_IS_PROD,
  FEATURE_SITECORE_LANDING_PAGE,
  isEnabled,
} from '@utils/features';

import { SITECORE_PRODUCTION_LAYOUT_URL } from '@sitecore/constants';
import { parameterizedlayoutService } from '@sitecore/services';

import { useLogger } from '@hooks/logger';

import { DIS } from '@typings/dis';
import { LogLevel } from '@typings/operations';

export const useSitecoreLayout = (
  userId: string | undefined,
  userType: string,
  itemPath: string,
  language?: string
) => {
  const [content, setContent] = useState<
    LayoutServiceData | FetchSiteCoreLayoutError | null
  >();
  const { addLog } = useLogger();

  const retryLayoutData = (
    userType: string,
    roleType: string | undefined,
    itemPath: string,
    language?: string
  ) => {
    return promiseRetry(
      {
        retries: 3, // The maximum amount of times to retry the operation.
        minTimeout: 500, // The number of milliseconds before starting the first retry.
        maxTimeout: 1000, // The maximum number of milliseconds between two retries.
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async retry => {
        try {
          if (
            userType !== SITECORE_USER_TYPES.USER_LOADING &&
            (isEnabled(FEATURE_SITECORE_HEADER) ||
              isEnabled(FEATURE_SITECORE_FOOTER) ||
              isEnabled(FEATURE_SITECORE_HOMEPAGE) ||
              isEnabled(FEATURE_SITECORE_LANDING_PAGE))
          ) {
            return await parameterizedlayoutService({
              user_type: userType,
              ...(roleType && { role_type: roleType }),
            }).fetchLayoutData(itemPath, language);
          }
          return null;
        } catch (err) {
          addLog({
            level: LogLevel.Error,
            message: (err as Error).toString(),
          });
          addLog({
            level: LogLevel.Info,
            message: 'retrying in 500 milliseconds...',
          });

          return {
            error: 'An error occurred while fetching SiteCore layout',
            errorMessage: (err as Error).toString(),
          };
        }
      }
    );
  };

  const leaderApps: string[] = ['0oagbul5pusSWPDzq0h7'];
  const hasAccess = (
    leaderApps: string[],
    response: DIS.GroupApplicationRolesRelationship[]
  ): boolean => {
    return response.some(user =>
      user.applications.some(app => leaderApps.includes(app.appInstanceId))
    );
  };

  const [basicInfo] = useBasicInfo();
  useEffect(() => {
    const fetchData = async () => {
      let roleType = '';

      // Get Basic Info
      if (userId) {
        const basicUserInfo = await basicInfo({
          variables: {
            emailId: userId,
          },
        });

        let userInfo = null;
        userInfo = (basicUserInfo?.data
          ?.basicInfo as unknown) as DIS.SSOTicketResponse;

        if (userInfo?.groupApplicationRolesRelationship?.length) {
          const isRoleTypeLeader = hasAccess(
            leaderApps,
            userInfo?.groupApplicationRolesRelationship
          );
          roleType = isRoleTypeLeader ? 'leader' : '';
        }
      }

      if (isEnabled(FEATURE_SITECORE_LANDING_PAGE)) {
        const path = window.location.pathname;
        let sitecorePaths: SitecoreRoute[] = [];

        try {
          const config: AxiosRequestConfig = {
            headers: {
              'Accept-Language': 'en-US',
            },
          };
          const response = await axios.get(
            String(
              `${
                isEnabled(FEATURE_SITECORE_IS_PROD)
                  ? SITECORE_PRODUCTION_LAYOUT_URL
                  : process.env.GATSBY_SITECORE_API_HOST
              }/en/api/sitecore/myrotaryroutesfeed`
            ),
            config
          );
          sitecorePaths = response?.data?.Results;

          if (
            !sitecorePaths?.length ||
            !sitecorePaths?.filter(sitecorePath => sitecorePath.Route === '/')
              ?.length
          ) {
            const content = await retryLayoutData(
              userType,
              roleType,
              itemPath,
              language
            );
            setContent(content);
            return;
          }

          if (
            sitecorePaths.filter(
              item =>
                item.Route === path ||
                `${item.Route}/` === path ||
                `/${language}${item.Route}` === path ||
                `/${language}${item.Route}/` === path
            ).length
          ) {
            let finalItemPath = path;

            finalItemPath =
              finalItemPath.slice(0, 3) === `/${language}`
                ? finalItemPath.slice(3)
                : finalItemPath;

            finalItemPath =
              finalItemPath === '/' ||
              finalItemPath[finalItemPath.length - 1] !== '/'
                ? finalItemPath
                : finalItemPath.slice(0, -1);

            const content = await retryLayoutData(
              userType,
              roleType,
              finalItemPath,
              language
            );
            setContent(content);
          } else {
            const content = await retryLayoutData(
              userType,
              roleType,
              '/',
              language
            );
            setContent(content);
          }
        } catch (err) {
          addLog({
            level: LogLevel.Error,
            message: `[UseSitecoreLayout]: An error occured while fetching sitecore routes. Error: ${(err as Error).toString()}`,
          });
        }
      } else {
        const content = await retryLayoutData(
          userType,
          roleType,
          itemPath,
          language
        );
        setContent(content);
      }
    };

    fetchData();
  }, [userType, itemPath, language]);

  return content;
};
